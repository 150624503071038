<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-card-title>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1" class="pa-0 text-right">
            <v-icon
              size="30"
              color="white"
              @click="toggleVideoModal({ show: false })"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="fill-height">
        <v-row justify="space-around">
          <v-col
            cols="12"
            sm="10"
            class="d-flex justify-space-around fill-height"
          >
            <div class="alignVertically">
              <v-alert
                v-if="isSafari"
                shaped
                prominent
                type="error"
                max-width="800"
              >
                Students must watch and participate with video in order For
                progress bar to update. Scrolling through to the end will not
                update video progress bar.
              </v-alert>
              <video-player
                class=""
                :options="videoOptions"
                v-if="show"
              ></video-player>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FullScreenImageiewer",
  components: {
    VideoPlayer: () => import("../../../VideoPlayer/VideoPlayer"),
  },
  data() {
    return {
      completeCheck: true,
      videoOptions: {
        autoplay: true,
        controls: true,
        // controlBar:{fullscreenToggle: false},
        width: "800",
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "videoSection/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleVideoModal({ show: value });
      },
    },
    videoObject() {
      return this.$store.state.videoSection.videoModal.videoObject;
    },
    videoSrc() {
      return this.$store.state.videoSection.videoModal.src;
    },
    isSafari() {
      let userAgent = window.navigator.userAgent
      return (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        ) || (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)));
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleVideoModal: "videoSection/toggleModal",
    }),
    openModal() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.videoOptions.width = 320;
      }
      this.videoOptions.sources[0].src = this.videoSrc;
    },
    closeModal() {
      this.videoOptions = {
        autoplay: true,
        controls: true,
        width: "800",
        responsive: true,
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
      };
    },
    watchvideo() {
      // console.log(this.imageViewer.obj)
      // console.log("iframe is update")
      let time = Math.round(
        (this.$refs.video.currentTime / this.$refs.video.duration) * 100
      );
      // console.log(time,this.$refs.video.currentTime,this.$refs.video.duration,"video")
      if (this.$route.matched[0].path.substring(1) === "parent") {
        if (time == 80 && this.completeCheck) {
          console.log(time, "80% has done");
          this.$root.$refs.videoSection.submitCompleted(
            this.imageViewer.obj,
            this.$refs.video.currentTime
          );
          this.completeCheck = false;
        }
      }
    },
  },
  mounted() {
    // console.log(this.videoOptions.sources[0].src, "video opt");
    // this.videoOptions.sources[0].src = this.imageViewer.src;
    // console.log(this.videoOptions.sources[0].src, "video opt");
    // console.log(this.imageViewer);
  },
};
</script>
<style scoped>
.alignVertically {
  margin:391px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Image {
  max-width: 800px;
  max-height: 550px;
}
.video {
  width: 65%;
}
.v-card {
  background-color: rgba(41, 48, 54, 0.82);
}
video::-webkit-media-controls-timeline {
  display: none;
}
@media only screen and (max-width: 600px) {
  .Image {
    width: 100%;
  }
  .video {
    width: 100%;
  }
}
</style>
